import React, {Fragment, useEffect, useState} from "react";
import {Layer, Rect, Stage, Text} from "react-konva";
import {HallOne} from "./components/HallOne";
import {Company} from "./interfaces/company";
import {API} from "./api";
import {HallTwo} from "./components/HallTwo";
import {ClickBox} from "./components/ClickBox";
import {message} from "antd";
import {CompanyDetails} from "./components/CompanyDetails";
import {Filter} from "./components/Filter";
import {FilterModel} from "./models/filter";
import {Branch} from "./interfaces/branch";
import {KonvaImage} from "./components/image";
import {Colors} from "./configs/colors";


interface AppProps {
    logo?: boolean;
    zoom?: boolean;
}

const colorWhite = '#ffffff';
const colorBlue = '#0ea2ff';

export const App = ({logo, zoom}: AppProps) => {
    const [ allCompanies, setAllCompanies ] = useState<Company[] | undefined>(undefined);
    const [ companies, setCompanies ] = useState<Company[] | undefined>(undefined);
    const [ company, setCompany ] = useState<Company | undefined>(undefined);
    const [ filter, setFilter ] = useState<FilterModel>(new FilterModel());
    const [ branches, setBranches ] = useState<Branch[]>([]);


    const stage = React.useRef<any>(null);

    const load = async () => {

        if ( stage.current ) {
            const width = stage.current.width();
            const height = stage.current.height();
            let offsetWidth = 0;
            stage.current.absolutePosition({x: width / 2 - 800, y: height / 2 - 200});

            if ( zoom ) {
                console.log('Zoom is active');
                //make zoom smaller
                stage.current.scale({ x: 0.8, y: 0.8});
            }
        }



        const api = await API.request<Company[]>('post', '/companies/');
        if ( !api ) return message.error('error');

        const api2 = await API.request<Branch[]>('post', '/branches/');
        if ( !api2 ) return message.error('error');

        setCompanies([...api]);
        setAllCompanies([...api]);

        setBranches([...api2]);

    }

    useEffect(() => {
        if ( !filter ) setFilter(new FilterModel());

        load();
        setCompanies([])
        setAllCompanies([])
    }, []);


    const onCompanyClicked = (stand: string | number) => showCompany(stand);

    const showCompany = (stand: string | number) => {
        const company = companies!.find((c) => c.standNumber === `${stand}`);
        if ( !company ) return message.error(`Kein Unternehmen gefunden für Stand: ${stand}`);

        setCompany(company);
    }

    const getCompany = (stand: string | number): Company | undefined => {
        return companies!.find((comp) => comp.standNumber && comp.standNumber === `${stand}`);
    }

    const filterCompanies = (filter: FilterModel | undefined) => {
        let companiesCopy = [...allCompanies!];

        if ( !filter ) {
            setCompanies([...allCompanies!]);
            setFilter({ ...new FilterModel() });
            return;
        }

        if (filter.companies.length > 0) {
            companiesCopy = companiesCopy.filter((comp) => {
                return filter.companies.includes(comp.name!);
            });

            setCompanies([...companiesCopy]);
            setFilter({...filter});
            return;
        }

        if (filter.branches.length > 0) {
            companiesCopy = companiesCopy.filter((comp) => {
                return filter.branches.includes(comp.branche);
            });
        }

        if (filter.careers.length > 0) {
            companiesCopy = companiesCopy.filter((comp) => {
                return comp.career.some((career) => filter.careers.includes(career.name));
            });
        }

        if (filter.school) {
            companiesCopy = companiesCopy.filter((comp) => {
                return comp.career.some((career) => career.type === "4")
            });
        }

        if (filter.studies) {
            companiesCopy = companiesCopy.filter((comp) => {
                return comp.career.some((career) => career.type === "3" || career.type === "2")
            });
        }

        if (filter.educations) {
            companiesCopy = companiesCopy.filter((comp) => {
                return comp.career.some((career) => career.type === "1")
            });
        }

        setCompanies([...companiesCopy]);
        setFilter({...filter});
    }

    return (<Fragment>
        {companies && <Filter companies={companies} onChange={filterCompanies} branches={branches} filter={filter} />}
        <Stage ref={stage} width={window.innerWidth - 35} height={window.innerHeight - 35} draggable>

            <HallOne />
            {/* Überdachterbereich */}
            <Layer>
                <Rect x={1200} y={200} width={100} height={300} fill={Colors.WALL} />
                <Rect x={1560} y={200} width={100} height={300} fill={Colors.WALL} />
                <Rect x={1200} y={500} width={460} height={100} fill={Colors.WALL} />
                <Text x={1290} y={540} text="überdachter Bereich" fontSize={30} fontVariant={"bold"} />
            </Layer>
            <HallTwo />

            {/* Hides logo only when specific url is called (for iFrame) */}
            { logo && <Layer>
                <KonvaImage x={150} y={-300} width={500} height={300} src={"https://jobklahr.de/resources/images/logo.svg"} />
            </Layer> }


            {/* Company Boxes [HALLE 1]*/}
            { companies && <Layer>

                {/* Rechts */}
                <ClickBox x={1280} y={600} width={100} height={80} stand={'A1'} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={740} y={264} height={100} stand={'AFA'} label={"Agentur für\nArbeit"} verticalText company={getCompany} clicked={onCompanyClicked} />

                {/* Oben */}

                <ClickBox x={740} y={162} height={100} stand={'S1'} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={602} y={130} width={100} stand={'S2'} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={550} y={130} stand={'S3'} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={498} y={130} stand={'S4'} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={396} y={130} width={100} stand={'S5'} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={344} y={130} stand={'S6'} company={getCompany} clicked={onCompanyClicked} />

                <Rect x={130} y={237} width={50} height={104} fill={colorBlue} />
                <Text x={145} y={322} text="BÜHNE" fontSize={20} fill={colorWhite} rotation={270} />


                {/* Unten */}

                <ClickBox x={575} y={415} width={100} stand={1} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={469} y={415} width={100} stand={2} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={417} y={415} stand={3} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={365} y={415} stand={4} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={313} y={415} stand={5} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={261} y={415} stand={6} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={209} y={415} stand={7} company={getCompany} clicked={onCompanyClicked} />

                {/* Links */}

                <ClickBox x={130} y={343} height={100} width={50} stand={8} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={130} y={135} height={100} width={50} stand={9} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={240} y={130} stand={10} company={getCompany} clicked={onCompanyClicked} />

                  {/* Mitte und Oben */}

                <ClickBox x={292} y={130} stand={11} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={593} y={250} width={102} height={102} stand={12} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={486} y={302} width={102} stand={13} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={434} y={302} stand={14} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={330} y={302} width={102} stand={15} company={getCompany} clicked={onCompanyClicked} />

                {/* Mitte */}

                <ClickBox x={226} y={302} width={102} stand={16} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={226} y={250} stand={17} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={278} y={250} stand={18} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={330} y={250} stand={19} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={382} y={250} stand={20} company={getCompany} clicked={onCompanyClicked} />

                <ClickBox x={434} y={250} stand={21} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={486} y={250} width={102} stand={22} company={getCompany} clicked={onCompanyClicked} />
                


            </Layer> }

            {/* Company Boxes [HALLE 2]*/}
            { companies && <Layer>


                {/* Unten */}

                <ClickBox x={950} y={-40} stand={23} width={102} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1060} y={-40} stand={24} width={102} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1170} y={-40} stand={25} width={102} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1280} y={-40} stand={26} width={102} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1385} y={-40} stand={27} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1438} y={-40} stand={28} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1491} y={-40} stand={29} company={getCompany} clicked={onCompanyClicked} />

                {/* Rechts */}

                <ClickBox x={1550} y={-162} height={100} stand={30} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1550} y={-216} stand={31} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1550} y={-268} stand={32} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1550} y={-320} stand={33} company={getCompany} clicked={onCompanyClicked} />

                {/* Oben */}

                <ClickBox x={1474} y={-435} stand={34} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1422} y={-435} stand={35} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1320} y={-435} width={100} stand={36} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1210} y={-435} width={100} stand={37} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1158} y={-435} stand={38} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1106} y={-435} stand={39} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1002} y={-435} stand={40} width={102} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={950} y={-435} stand={41} company={getCompany} clicked={onCompanyClicked} />
               

                {/* Mitte */}

                <ClickBox x={980} y={-250} width={102} height={102} stand={46} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1095} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1095} y={-198} stand={47} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1095} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1148} y={-198} stand={48} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1148} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1201} y={-198} stand={49} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1201} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1254} y={-198} stand={50} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1254} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1307} y={-198} stand={51} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1307} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1360} y={-198} stand={52} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1360} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1413} y={-198} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1413} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1466} y={-198} stand={""} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={1466} y={-250} stand={""} company={getCompany} clicked={onCompanyClicked} />
               
              

                {/* Links */}

                <ClickBox x={870} y={-166} stand={44}  company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={870} y={-114} stand={45}  company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={870} y={-268} stand={43} height={100} company={getCompany} clicked={onCompanyClicked} />
                <ClickBox x={870} y={-320} stand={42} company={getCompany} clicked={onCompanyClicked} />

                

            </Layer> }
        </Stage>

        {/*if company is selected by clicking on box*/}
        {company && <CompanyDetails company={company} onClose={() => setCompany(undefined)} /> }
    </Fragment>);
}
