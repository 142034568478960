import { Arrow, Layer, Rect, Text } from "react-konva";
import { KonvaImage } from "./image";
import React from "react";
import {Colors} from "../configs/colors";


export const HallTwo = () => {
    const wallColor = '#ccc'; // Graue Farbe für die Wände
    const doorColor = Colors.DOORS;

    return (
        <Layer>
            <Rect x={810} y={-440} width={850} height={500} fill={wallColor} />
            <KonvaImage x={870} y={-425} width={60} height={30} src={"exit.png"} />
            <KonvaImage x={1545} y={-425} width={60} height={30} src={"exit.png"} />
            <KonvaImage x={1545} y={-30} width={60} height={30} src={"exit.png"} />

            <Arrow x={1150} y={-312} points={[100,0, 0, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={1150} y={-100} points={[0,0, 100, 0]} fill={"black"} stroke={"black"} />
            <Arrow x={950} y={-250} points={[0,0, 0, 100]} fill={"black"} stroke={"black"} />
            <Arrow x={1535} y={-250} points={[0,100, 0, 0]} fill={"black"} stroke={"black"} />

            <Rect x={755} y={-27} width={80} height={35} fill={doorColor} />
            <Rect x={755} y={-27} width={80} height={35} fill={doorColor} />
            <Text x={770} y={-17} text="Eingang" fontSize={15} />
        </Layer>
    );
}
